import { useRouter } from "next/router"
import { last, split } from "ramda"
import React, { useContext, useState } from "react"
import { FormattedMessage } from "react-intl"
import { Modal, ModalBody, ModalHeader } from "reactstrap"
import { talentBerlinPageNames } from "./config/talent-berlin-pages"
import { CompanyGroupContext } from "../../lib/company-group-context"
import { config } from "../../lib/config"
import { Image } from "../shared/image"
import { TalentBerlinBanner } from "./talent-berlin-banner"
import { TrackingLabelContext } from "../../lib/tracking-label-context"

const TalentBerlinHero: React.FC = () => {
  const [isBotModalVisible, setIsBotModalVisible] = useState(false)
  const companyGroup = useContext(CompanyGroupContext)
  const { trackingLabel } = useContext(TrackingLabelContext)
  const router = useRouter()

  const params = []
  let signupUrl = `${config.urls.candidateApp}/signup`

  const resource = last(split("/", router.asPath)) || ""
  const pageName = talentBerlinPageNames.includes(resource) ? resource : null

  const openBotModal = () => {
    setIsBotModalVisible(true)
  }

  const closeBotModal = () => {
    setIsBotModalVisible(false)
  }

  if (companyGroup) {
    params.push(`ref=g-${companyGroup.id}`)
  }

  if (trackingLabel) {
    params.push(`utm_content=${trackingLabel}`)
  }

  if (params.length) {
    signupUrl += `?${params.join("&")}`
  }

  return (
    <>
      <Modal
        toggle={closeBotModal}
        size="lg"
        isOpen={isBotModalVisible}
        centered
      >
        <ModalHeader className="hero-modal-header" toggle={closeBotModal}>
          <FormattedMessage
            id="talentberlin.bot.modal.header"
            defaultMessage="Choose your preferred platform to create your profile at MoBerries"
          />
        </ModalHeader>
        <ModalBody>
          <div className="hero-modal-content">
            <a
              className="btn btn-light hero-modal-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://t.me/MoBerries_Bot"
            >
              <Image
                className="hero-modal-link__icon"
                width={48}
                height={48}
                src="/web-catalog-app/assets/talent-berlin/telegram.svg"
                alt="telegram icon"
              />
              Telegram
            </a>

            <a
              className="btn btn-light hero-modal-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://api.whatsapp.com/send/?phone=4915735991707"
            >
              <Image
                className="hero-modal-link__icon"
                width={48}
                height={48}
                src="/web-catalog-app/assets/talent-berlin/whatsapp.svg"
                alt="whatsapp icon"
              />
              WhatsApp
            </a>

            <a
              className="btn btn-light hero-modal-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://m.me/Moberries"
            >
              <Image
                className="hero-modal-link__icon"
                width={48}
                height={48}
                src="/web-catalog-app/assets/talent-berlin/facebook.svg"
                alt="facebook icon"
              />
              Facebook
            </a>
          </div>
        </ModalBody>
      </Modal>
      <section className="hero-section bg-white">
        <TalentBerlinBanner pageName={pageName} />

        <div className="container details">
          <div className="details__info-block">
            <h3 className="details__section-title">
              <FormattedMessage
                id="talentberlin.info.title"
                defaultMessage="Say NO to manual job search!"
              />
            </h3>
            <ul className="details__info-list">
              <li className="details__info-list-item">
                <FormattedMessage
                  id="talentberlin.info.list.item1"
                  defaultMessage="Develop your career, discover your next job"
                />
              </li>
              <li className="details__info-list-item">
                <FormattedMessage
                  id="talentberlin.info.list.item2"
                  defaultMessage="No time for searching, let us help you discover new jobs"
                />
              </li>
              <li className="details__info-list-item">
                <FormattedMessage
                  id="talentberlin.info.list.item3"
                  defaultMessage="Boost your job search"
                />
              </li>
            </ul>
            <p className="details__text">
              <FormattedMessage
                id="talentberlin.info.text"
                defaultMessage="MoBerries & Berlin Partner enable you to discover jobs in Berlin."
              />
            </p>
            <a
              className="details__discover-link"
              href="#talent-berlin-partners"
            >
              <FormattedMessage
                id="talentberlin.info.cta"
                defaultMessage="Discover jobs now!"
              />
            </a>
          </div>

          <div className="details__instruction-block">
            <h3 className="details__section-title details__section-title_instruction">
              <FormattedMessage
                id="talentberlin.instruction.title"
                defaultMessage="How does it work?"
              />
            </h3>
            <ol className="details__instruction-list">
              <li className="details__instruction-list-item">
                <FormattedMessage
                  id="talentberlin.instruction.list.item1"
                  defaultMessage="Register"
                />
              </li>
              <li className="details__instruction-list-item">
                <FormattedMessage
                  id="talentberlin.instruction.list.item2"
                  defaultMessage="Create your CV (optional)"
                />
              </li>
              <li className="details__instruction-list-item">
                <FormattedMessage
                  id="talentberlin.instruction.list.item3"
                  defaultMessage="Accept or decline your job matches"
                />
              </li>
              <li className="details__instruction-list-item">
                <FormattedMessage
                  id="talentberlin.instruction.list.item4"
                  defaultMessage="Apply within one click"
                />
              </li>
              <li className="details__instruction-list-item">
                <FormattedMessage
                  id="talentberlin.instruction.list.item5"
                  defaultMessage="Get hired in Berlin"
                />
              </li>
            </ol>
          </div>

          <div className="details__links-block">
            <h3 className="details__section-title details__section-title_links">
              <FormattedMessage
                id="talentberlin.links.title"
                defaultMessage="One platform for all cases"
              />
            </h3>
            <ul className="details__links-list">
              <li className="details__links-list-item">
                <a
                  className="details__link-card"
                  href={signupUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <strong>
                    <FormattedMessage
                      id="talentberlin.links.cv.title"
                      defaultMessage="Do you already have a CV?"
                    />
                  </strong>
                  <br />
                  <FormattedMessage
                    id="talentberlin.links.cv.text"
                    defaultMessage="Start your journey here and create your profile within a few steps."
                  />
                </a>
              </li>
              <li className="details__links-list-item">
                <button className="details__link-card" onClick={openBotModal}>
                  <strong>
                    <FormattedMessage
                      id="talentberlin.links.nocv.title"
                      defaultMessage="You do not have a CV?"
                    />
                  </strong>
                  <br />
                  <FormattedMessage
                    id="talentberlin.links.nocv.text"
                    defaultMessage="We will help you create one in minutes through messenger. Please click here to proceed."
                  />
                </button>
              </li>
              <li className="details__links-list-item">
                <a
                  className="details__link-card"
                  href="#talent-berlin-partners"
                >
                  <strong>
                    <FormattedMessage
                      id="talentberlin.links.browse.title"
                      defaultMessage="Want to browse jobs inventory?"
                    />
                  </strong>
                  <br />
                  <FormattedMessage
                    id="talentberlin.links.browse.text"
                    defaultMessage="Simply scroll down and discover our inventory."
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}

export default TalentBerlinHero
