import { keys } from "ramda"

export const talentBerlinPages = {
  africa: ["/web-catalog-app/assets/talent-berlin/africa-ghana_slide.jpg"],
  brazil: ["/web-catalog-app/assets/talent-berlin/brazil-rio_slide.jpg"],
}

export const talentBerlinPageNames: string[] = keys(talentBerlinPages)

export const defaultCarouselImage =
  "/web-catalog-app/assets/talent-berlin/berlin_slide.jpg"
