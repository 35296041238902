import { mapObjIndexed } from "ramda"
import React from "react"
import { UncontrolledCarousel } from "reactstrap"
import {
  defaultCarouselImage,
  talentBerlinPages,
} from "./config/talent-berlin-pages"

interface CarouselItem {
  key: number
  src: string
  caption: string
}

interface CarouselConfig {
  [key: string]: CarouselItem[]
}

const defaultCarouselItem: CarouselItem[] = [
  {
    key: 0,
    src: defaultCarouselImage,
    caption: "",
  },
]

const carouselConfig: CarouselConfig = mapObjIndexed(
  value =>
    value.map((url, i) => ({
      key: i,
      src: url,
      caption: "",
    })),
  talentBerlinPages,
)

export const TalentBerlinBanner: React.FC<{
  pageName: string | null
}> = ({ pageName }) => {
  const items = pageName ? carouselConfig[pageName] : defaultCarouselItem

  return (
    <div className="container hero">
      <UncontrolledCarousel
        controls={items.length > 1}
        indicators={false}
        items={items}
      />
    </div>
  )
}
